import { Button, Stack, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postAPI } from 'lib/APIHelpers';
import { emailRegex, hasLocalStorageAvailable } from 'lib/Utils';
import { EmailFormField } from './EmailFormField';

export const SendResponses = ({ feedbackID }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();

  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(undefined);
  const { t } = useTranslation();

  const responsesSentKey = `responses_sent_${feedbackID}`;
  const responsesSent = hasLocalStorageAvailable ? localStorage.getItem(responsesSentKey) : sent;

  const setResponsesSent = useCallback(
    () => {
      if (hasLocalStorageAvailable) {
        localStorage.setItem(responsesSentKey, true);
      } else {
        setSent(true);
      }
    },
    [responsesSentKey]
  );

  const onChangeEmail = useCallback(
    (e) => {
      setEmail(e.target.value);
      setError(undefined);
    },
    []
  );

  const onChangeConfirmation = useCallback(
    (e) => {
      setEmailConfirmation(e.target.value);
      setConfirmationError(undefined);
    },
    []
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      if (!emailRegex.test(email)) {
        setError(t('Please enter a valid email.'));
      }

      if (!emailRegex.test(emailConfirmation)) {
        setConfirmationError(t('Please enter a valid email.'));
      }

      if (email !== emailConfirmation) {
        setConfirmationError(t('Email addresses don\'t match.'));
        setLoading(false);
        return;
      }

      if (error || confirmationError) {
        setLoading(false);
        return;
      }

      try {
        await postAPI(
          `api/v2/feedback/${feedbackID}/export_responses`,
          { email: email, email_confirmation: emailConfirmation }
        );
        setResponsesSent();

      } catch (e) {
        if (e.response.status == 422) {
          setError(t('Please enter a valid email.'));
        } else {
          setError(e.message);
        }
      }
      setLoading(false);
    },
    [
      feedbackID,
      email,
      emailConfirmation,
      error,
      confirmationError,
      setResponsesSent,
      t
    ]
  );

  return (
    <>
      <Stack
        alignItems={'center'}
        spacing={1}
        mb={3}
        data-component={'send-responses'}
      >
        <Typography>
          <strong>{t("What's next?")}</strong>
        </Typography>
        <Typography textAlign={'center'}>
          {t("You can email yourself a copy of your answers or if you're done, close this page to exit.")}
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        alignItems={responsesSent ? 'center' : 'start'}
      >
        <Typography>
          <strong>{t('Email yourself a copy of your answers')}</strong>
        </Typography>
        {responsesSent == undefined ? (
          <form
            onSubmit={onSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <EmailFormField
              label={t('Enter your email')}
              value={email}
              name={'email'}
              onChange={onChangeEmail}
              error={error}
              testId={'email-input'}
            />
            <EmailFormField
              label={t('Confirm your email')}
              value={emailConfirmation}
              name={'email-confirmation'}
              onChange={onChangeConfirmation}
              error={confirmationError}
              testId={'confirmation-email-input'}
            />
            <Button
              size={'lg'}
              type={'submit'}
              loading={loading}
              loadingPosition={'end'}
              onClick={onSubmit}
            >
              {t('Send answers')}
            </Button>
          </form>
        ) : (
          <Stack
            alignItems={'center'}
            spacing={1}
          >
            <Typography>
              {t('Email sent!')}
            </Typography>
            <Typography>
              {t('It might take a few minutes to show in your inbox.')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};
